//Local system
// export const BASE_API_URL = "http://localhost:3000/admin/";
// export const VENDOR_API_BASE_URL = "http://localhost:4007/api/v1/";
// export const BASE_URL = "http://localhost:3001/"

//Live system
export const BASE_API_URL = "https://api.eventstan.com/admin/";
export const VENDOR_API_BASE_URL = "https://vendorapi.eventstan.com/api/v1/"
export const BASE_URL = "https://eventstan.com/"

//UAT system
// export const BASE_API_URL = "https://uatapi.eventstan.com/admin/";
// export const VENDOR_API_BASE_URL = "https://uatvendorapi.eventstan.com/api/v1/"
// export const BASE_URL = "https://uatcustomer.eventstan.com/"

import { useState,useEffect,useContext } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import TableHeader from '../../components/table/TableHeader';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withRouter,useParams  } from 'react-router-dom';
import userContext from "../../context/userContext";
import {BASE_API_URL,BASE_URL} from '../../constant';
import moment from "moment";
import Button from '@mui/material/Button';
import UnixResolver from "../../components/UnixResolver";

const BookedEventDetails = ({ history }) => {
  const { userData, setUserData } = useContext(userContext);
  const [eventData, setEventData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [eventType, setEventType] = useState();
  const [eventBookingLog, setEventBookingLog] = useState([]);
  const [BookingCard, setBookingCard] = useState([]);
  const [BookedServiceData, setBookedServiceData] = useState([]);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const params = useParams()
  const classes = useStyles();
  
  const getBookingDetails = async () => {
    let total=0;
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
   const response = await fetch(
    `${BASE_API_URL}get-booked-event-details?userEventId=${params.eventId}`, options);
    const data = response.json();
    data.then((data) => { 
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setEventData(data.data[0]);
      setBookingData(data.data[0].booking[0]);
      setEventType(data.data[0].eventTypes.name);
      setEventBookingLog(data.data[0].eventLog)
      setBookingCard(data.data[0].card)
      getBookedServices(data.data[0].userId)
      data.data[0].eventLog.map((s)=>{
        total = total + s.intialPaymentPaid
     })
     setReceivedAmount(total);
    })
    .catch((error) => console.log(error));
  }

  const getBookedServices = async (uid) =>{
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(`${BASE_API_URL}getEventDraft?userEventId=${params.eventId}&userId=${uid}`, options);
    const data = response.json();
    data.then((data) => { 
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setBookedServiceData(data.data.data[0].vendorServices);
    })
    .catch((error) => console.log(error));
  }

  useEffect(()=>{
   getBookingDetails();
  },[])

  const sendReview = (eventId)=>{
    let options = {
      method: "POST",
      headers: {
          Authorization: userData.token,
      },
      body:  JSON.stringify({
          "eventId": eventId,
          "userName": eventData.user[0].firstName,
          "userEmail": eventData.user[0].email,
          "url": BASE_URL
      })
   };
   try
    {
      const response = fetch(`${BASE_API_URL}send-review-mail`,options);
      alert('Review mail sent.');
    }
    catch(err){
      alert("Error" + err)
    }

  }

  return (
    <Paper className={classes.paper} style={{ margin: 30 }}>
      {/* <TableHeader
        lable="Booked Event Details"
        btnName={`Approve ${tab}`}
        value={search}
        onChange={e => setSearch(e.target.value)}
      /> */}
      <Divider />
     
      <Grid container className={classes.pageBody}>
        <Grid container xs={4} className={classes.pageBody}>
          <div>
            <span className={classes.head} >{eventData.eventName}</span><br/>
            <span>{eventData.eventAddress !='' ? eventData.eventAddress : eventData.user[0].ship_add.address +','+eventData.user[0].ship_add.city}</span><br/>
            
          </div>
          <div id="payment" style={{padding: '40px 0px', width:'100%'}}>

              <span className={classes.subhead}>PAYMENT MILESTONE</span>
            
            {/*<Card>
            <CardContent>*/}
            {eventBookingLog.map((book,i)=>{
              return <><Grid container style={{ marginTop: '20px'}}>
              <Grid container xs={6}>
                    <span> Payment Milestone {i+1} </span> <br/>
                    <span style={{fontSize: '12px', color: 'green'}}>Paid </span>   
                    <br/>
              </Grid>
              <Grid container xs={2}>
                    &nbsp;
              </Grid>
              <Grid container xs={4}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        AED {book.intialPaymentPaid} 
                    </Typography>
              </Grid>
            </Grid> 
            <Divider/>
            </>
            })}
            { eventBookingLog.map((book1,i)=> {
            { return  book1.couponApplied ? 
              <Grid container style={{ marginTop: '20px'}}>
              <Grid container xs={6}>
                    <span>Discount Amount </span> <br/>
                    {/* <span style={{fontSize: '12px', color: 'red'}}> Due Date 21 March, 2022 </span>   */}
                    <br/>       
              </Grid>
              <Grid container xs={2}>
                    &nbsp;
              </Grid>
              <Grid container xs={4}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        AED {(book1.totalPrice - book1.intialPaymentPaid).toFixed(1)} 
                    </Typography>
              </Grid>
            </Grid> 
            : eventData.packagePrice!=receivedAmount?<Grid container style={{ marginTop: '20px'}}>
            <Grid container xs={6}>
                  <span>Balance Amount </span> <br/>
                  <span style={{fontSize: '12px', color: 'red'}}> Due Date {moment(eventData.eventDate).subtract(2, "days").format('MMM DD YYYY')} </span>  
                  <br/>       
            </Grid>
            <Grid container xs={2}>
                  &nbsp;
            </Grid>
            <Grid container xs={4}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  AED {(book1.totalPrice - book1.intialPaymentPaid).toFixed(1)} 
                  </Typography>
            </Grid>
          </Grid>:''
           }
          })} 
            <Divider/>
            <Grid container style={{ marginTop: '20px'}}>
              <Grid container xs={6}>
                    <span>Final Amount </span> <br/>
                    {/* <span style={{fontSize: '12px', color: '#cccccc'}}>2 Days before event.</span> */}
                    <br/>         
              </Grid>
              <Grid container xs={2}>
                    &nbsp;
              </Grid>
              <Grid container xs={4}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        AED {eventData.packagePrice} 
                    </Typography>
              </Grid>
            </Grid>  
            <Divider/>
          </div>
          <div id="services" style={{padding: '40px 0px', width:'100%'}}>
            <span className={classes.subhead}>SERVICES BOUGHT</span>
            {BookedServiceData.map((serv,i)=>{
              return <>
              <Grid container style={{ marginTop: '20px'}}>
              <Grid container xs={6}>
                    <span>{serv.title}</span> 
                    <br/>
                    <span style={{fontSize: '12px', color: 'red'}}>{serv.serviceType} </span>   
                    <br/>
              </Grid>
              <Grid container xs={2}>
                    &nbsp;
              </Grid>
              <Grid container xs={4}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        AED {serv.priceType==0?(serv.price*serv.numberOfDays):(serv.price*serv.guests*serv.numberOfDays)}
                    </Typography>
              </Grid>
            </Grid>  
            <Divider/>
              </>
            })}
          </div>
        </Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
        <br/>
            <br/>
            <br/>
            <h4 style={{color:'green'}}>AED {receivedAmount}</h4>
            <span className={classes.subhead}>Payment Received</span>
            <br/>
            <br/>
            <br/>
           <span>Event Type : <strong>{eventType}</strong></span><br/>
           <br/>
           <br/>
           <strong>Transaction Details</strong><br/>
           <br/>
          {eventBookingLog.map((cards,i)=>{
            return <>
            <span>Date :  { moment(eventBookingLog[i].createdAt).format('MMM DD YYYY HH:mm')}</span><br/>
            <span>Txn ID : <b>{cards.paymentToken}</b> </span><hr/></>
          })}
           <br/>
           <br/>
           <strong>Booking Details</strong><br/>
           <br/>
           
           <span>Booking Id :  <b>{eventData.sysEventId}</b></span><br/>
           <span>Date and Time :  {moment(parseInt(UnixResolver(eventData.eventDate))).format('MMM DD YYYY')} ({moment(parseInt(bookingData.startTime)).format('HH:mm')} - {moment(parseInt(bookingData.endTime)).format('HH:mm')})</span><br/>
           <br/>
           <br/>
           
           <Button
            variant="contained"
            disableElevation
            className={classes.activeButton}
          >{eventData.isApproved?'Approved':'Not Approved'}</Button>
          <Button variant="contained" disableElevation onClick={()=>sendReview(eventData._id)}>Send review mail</Button>
           <br/>
           <br/>
           <br/>
        </Grid>
    </Grid>
    </Paper>
  );
}

export default withRouter(BookedEventDetails);



const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#F2F2F2",
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  buttonContainer: {
    backgroundColor: '#FFF',
    width: '30%',
    '&>*': {
      width: '50%',
      borderRadius: 0,
      textTransform: 'none'
    },
  },

  pageBody:
    {
    backgroundColor: '#FFF', 
    paddingTop: '20px', 
    paddingLeft: '20px'
  },

  head:{
    fontSize:'30px',
    fontStyle:'bold',
  },

  subhead:{
    fontSize:'15px',
    fontWeight:'bold',
  },

  activeButton: {
    border: '2px solid #f47824',
    backgroundColor: '#f47824',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#f47824'
    }
  }
}));
